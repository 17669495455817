import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer, MobileContainer } from '../components/ui/Div'
import Button from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import Image from '../components/ui/Image'
import { colors, smallBoxShadow } from '../components/ui/helpers.js'
import { urlToString } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Text.js'
import OnlineIcon from '../components/ui/OnlineIcon'

import Layout from '../components/layout'
import SideMenu from '../components/SideMenu'
import DesktopSideBar from '../components/DesktopSideBar'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'
import Header from '../components/Header'

const FullHeightContainer = styled(Flex)`
  min-height: 100vh;
`

const AboutPage = ({}) => {
  return (
    <Layout>
      <Helmet
        title={'About SMS-Temp | SMS-Temp'}
        lang="en"
        description={
          'Protect your privacy and security with our free temporary phone numbers service. Receive unlimited SMS on exclusive, real SIM numbers.'
        }
      >
        <html lang={'en'} />
      </Helmet>
      <FullHeightContainer flexDirection="column" alignItems="center">
        <Header />
        <Flex maxWidth="900px" pt="60px" flexDirection="column">
          <H1 mb="20px">About</H1>
          <Text mb="40px">
            SMS-Temp offers exclusive temporary numbers for users to use. Protect your privacy and security with our free temporary phone numbers
            service.
          </Text>
          <Text mb="40px">
            Built by engineering nerds concerned by privacy, we banded together to bring SMS-Temp online for others to use. Our service is free and
            will always remain so. Built with love in London, UK.
          </Text>
          <H1 mb="20px">Contact us</H1>
          <Text>Contact us at sms-temp@gmail.com for help using our services or new feature requests.</Text>
        </Flex>
      </FullHeightContainer>
      <Footer iso2={'en'} />
    </Layout>
  )
}
export default AboutPage

//http://localhost:8000/united-kingdom/447901614024
// d1uyh0a3eznojw.cloudfront.net/united-kingdom/447901614024
